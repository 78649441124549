import React from "react";
import { useState, useEffect } from "react";
import ".././CSS/FirstScreen.css";
import { motion } from "framer-motion";
import { InputGroup, Input } from "reactstrap";
import JCalc from "../JCalc.js";

export const FirstScreen = ({
  onTowerSelection,
  companyName,
  address,
  acres,
  power,
  setCompanyName,
  setAddress,
  setAcres,
  setPower,
  checkAcres,
  checkPower,
  setcheckAcres,
  setcheckPower,
  energyPrice,
  setEnergyPrice,
}) => {
  const [jcalc, setJcalc] = useState(
    new JCalc(power, acres, energyPrice, null, 10),
  );
  useEffect(() => {
    setJcalc(new JCalc(power, acres, energyPrice, null, 10));
  }, []);
  return (
    <>
      <motion.div className="screen">
        <motion.div className="background"></motion.div>
        <motion.img
          animate={{
            y: [50, 0],
            opacity: [0, 100],
          }}
          transition={{ duration: 1 }}
          src="logos/logo_mixed_black.png"
          className="im-logo"
        />
        <motion.div
          animate={{
            y: [50, 0],
            opacity: [0, 100],
          }}
          transition={{ duration: 1, delay: 0.4 }}
          className="customer-select shadow-lg"
        >
          <motion.span className="fw-bold customer-select-title text-center">
            What is the name of the company you are sending it to?
          </motion.span>
          <InputGroup className="customer-select-input">
            <div className="border-inputs">
              <Input
                className="round-left input-color customer-select-dropdown"
                placeholder={companyName}
                onChange={(event) => {
                  const stringValue = event.target.value;
                  setCompanyName(stringValue);
                }}
              ></Input>
            </div>
          </InputGroup>
        </motion.div>
        <motion.div
          animate={{
            y: [50, 0],
            opacity: [0, 100],
          }}
          transition={{ duration: 1, delay: 1 }}
          className="customer-select shadow-lg"
        >
          <motion.span className="fw-bold customer-select-title text-center">
            What is the address of the company?
          </motion.span>
          <InputGroup className="customer-select-input">
            <div className="border-inputs">
              <Input
                className="round-left input-color customer-select-dropdown"
                placeholder={address}
                onChange={(event) => {
                  const stringValue = event.target.value;
                  setAddress(stringValue);
                }}
              ></Input>
            </div>
          </InputGroup>
        </motion.div>
        <motion.div
          animate={{
            y: [50, 0],
            opacity: [0, 100],
          }}
          transition={{ duration: 1, delay: 1 }}
          className="customer-select shadow-lg"
        >
          <motion.span className="fw-bold customer-select-title text-center">
            What is the energy price?
          </motion.span>
          <InputGroup className="customer-select-input">
            <div className="border-inputs">
              <Input
                className="round-left input-color customer-select-dropdown"
                placeholder={energyPrice}
                onChange={(event) => {
                  const numericValue = event.target.value.replace(
                    /[^0-9.]/g,
                    "",
                  );
                  setEnergyPrice(numericValue);
                }}
              ></Input>
              <span>$/W</span>
            </div>
          </InputGroup>
        </motion.div>
        <motion.div
          animate={{
            y: [50, 0],
            opacity: [0, 100],
          }}
          transition={{ duration: 1, delay: 1 }}
          className="customer-select shadow-lg"
        >
          <motion.span className="fw-bold customer-select-title text-center">
            How many acres do they need?
          </motion.span>
          <InputGroup className="customer-select-input">
            <div className="border-inputs">
              <Input
                className="round-left input-color customer-select-dropdown"
                placeholder={acres}
                onChange={(event) => {
                  setcheckAcres(1);
                  const numericValue = event.target.value.replace(
                    /[^0-9.]/g,
                    "",
                  );
                  const tempjcalc = JCalc.fromArea(
                    Number(numericValue),
                    energyPrice,
                    null,
                    10,
                  );
                  setPower(tempjcalc.getTotalCapacity());
                  setAcres(numericValue);
                  setJcalc(tempjcalc);
                }}
                disabled={checkPower !== 0}
              ></Input>
              <span>acres</span>
            </div>
          </InputGroup>
        </motion.div>
        <motion.div
          animate={{
            y: [50, 0],
            opacity: [0, 100],
          }}
          transition={{ duration: 1, delay: 1 }}
          className="customer-select shadow-lg"
        >
          <motion.span className="fw-bold customer-select-title text-center">
            What is the scope of the project?
          </motion.span>
          <InputGroup className="customer-select-input">
            <div className="border-inputs">
              <Input
                className="round-left input-color customer-select-dropdown"
                placeholder={power}
                onChange={(event) => {
                  const stringValue = event.target.value;
                  setPower(stringValue);
                  setJcalc(
                    JCalc.fromScope(
                      Number(stringValue) * 1000,
                      energyPrice,
                      null,
                      10,
                    ),
                  );
                  setAcres(
                    Math.ceil(
                      JCalc.convertFeetToAcres(
                        JCalc.getLandForCapacity(
                          Number(stringValue) * 1000,
                          10,
                        ),
                      ),
                    ),
                  );
                  setcheckPower(1);
                }}
                disabled={checkAcres !== 0}
              ></Input>
              <span>kW</span>
            </div>
          </InputGroup>
        </motion.div>
        <motion.div
          animate={{
            y: [50, 0],
            opacity: [0, 100],
          }}
          transition={{ duration: 1, delay: 1.5 }}
          className="proceed-holder"
        >
          <motion.button
            className="btn btn-warning proceed button-color"
            onClick={onTowerSelection}
          >
            Proceed{" "}
            <motion.img
              src="logos/right-arrow.png"
              alt="proceed arrow"
              className="proceed-img"
            />
          </motion.button>
        </motion.div>
      </motion.div>
    </>
  );
};
