import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import "./Proposal.css";
import JCalc from "./JCalc.js";
import fontkit from "@pdf-lib/fontkit";
import FijallaOne from "./fonts/FjallaOne-Regular.ttf";
import NunitoB from "./fonts/Nunito-Bold.ttf";
import Roboto from "./fonts/Roboto.ttf";
import RobotoB from "./fonts/Roboto-Bold.ttf";

import {
  Col,
  Card,
  CardTitle,
  CardHeader,
  Row,
  CardBody,
  Input,
  Label,
} from "reactstrap";
import { FirstScreen } from "./Components/FirstScreen";
import { PDFDocument, rgb } from "pdf-lib";

export const Proposal = () => {
  const [companyName, setCompanyName] = useState("Janta");
  const [address, setAddress] = useState("123 Main St.");
  const [acres, setAcres] = useState(0.36);
  const [checkAcres, setcheckAcres] = useState(0);
  const [checkPower, setcheckPower] = useState(0);
  const [power, setPower] = useState(100);
  const [energyPrice, setEnergyPrice] = useState(3);
  const [pdfData, setPdfData] = useState(null);
  const [reap, setReap] = useState(false);
  const [tax, setTax] = useState(true);

  const [jcalc, setJcalc] = useState(new JCalc(power, acres, 0.14, null, 10));

  const [startupFinished, setStartupFinished] = useState(false);

  async function generatePDF() {
    const url = "/templates/Template2.pdf";
    const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());

    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    pdfDoc.registerFontkit(fontkit);

    const FijallaFontBytes = await fetch(FijallaOne).then((res) =>
      res.arrayBuffer(),
    );
    const FijallaFont = await pdfDoc.embedFont(FijallaFontBytes);

    const NunitoBoldFontBytes = await fetch(NunitoB).then((res) =>
      res.arrayBuffer(),
    );
    const NunitoBold = await pdfDoc.embedFont(NunitoBoldFontBytes);

    const RobotoFontBytes = await fetch(Roboto).then((res) =>
      res.arrayBuffer(),
    );
    const RobotoFont = await pdfDoc.embedFont(RobotoFontBytes);

    const RobotoBoldFontBytes = await fetch(RobotoB).then((res) =>
      res.arrayBuffer(),
    );
    const RobotoBoldFont = await pdfDoc.embedFont(RobotoBoldFontBytes);

    const pages = pdfDoc.getPages();
    const firstPage = pages[0];
    firstPage.drawText(`${companyName}`, {
      x: 378,
      y: 240,
      size: 20,
      font: FijallaFont,
      color: rgb(0, 0, 0),
    });
    firstPage.drawText(`${address}`, {
      x: 378,
      y: 223,
      size: 12,
      font: NunitoBold,
      color: rgb(1, 1, 1),
    });

    const secondPage = pages[1];
    secondPage.drawText(`${companyName}`, {
      x: 55,
      y: 714,
      size: 20,
      font: FijallaFont,
      color: rgb(1, 1, 1),
    });
    secondPage.drawText(`${address}`, {
      x: 55,
      y: 680,
      size: 16,
      font: NunitoBold,
      color: rgb(1, 1, 1),
    });

    const fifthPage = pages[4];
    fifthPage.drawText(`${JCalc.kwToActual(power)} solar`, {
      x: 483,
      y: 608.5,
      size: 11,
      font: RobotoFont,
      color: rgb(0, 0, 0),
    });
    fifthPage.drawText(
      `${energyPrice} dollars per watt. On the ${energyPrice} dollar evaluation, the price will be ${getMoneyValue(jcalc.getTrueProjectCost(energyPrice, reap, tax, false))}`,
      {
        x: 210,
        y: 526,
        size: 11,
        font: RobotoFont,
        color: rgb(0, 0, 0),
      },
    );
    fifthPage.drawText(
      `${getMoneyExtension(power * 1000 * energyPrice)} dollars.`,
      {
        x: 53,
        y: 510,
        size: 11,
        font: RobotoFont,
        color: rgb(0, 0, 0),
      },
    );
    fifthPage.drawText(`${address}`, {
      x: 48,
      y: 420,
      size: 16,
      font: RobotoBoldFont,
      color: rgb(0, 0, 0),
    });
    fifthPage.drawText(`${JCalc.kwToActual(power)}`, {
      x: 54,
      y: 310,
      size: 11,
      font: RobotoFont,
      color: rgb(0, 0, 0),
    });
    fifthPage.drawText(`${energyPrice - 0.5} - ${energyPrice.toFixed(1)}`, {
      x: 190,
      y: 318,
      size: 11,
      font: RobotoFont,
      color: rgb(0, 0, 0),
    });
    fifthPage.drawText("JPT10, ", {
      x: 254,
      y: 318,
      size: 11,
      font: RobotoFont,
      color: rgb(0, 0, 0),
    });
    fifthPage.drawText(`${power / 10}`, {
      x: 274,
      y: 300.5,
      size: 11,
      font: RobotoFont,
      color: rgb(0, 0, 0),
    });
    fifthPage.drawText(`${acres} acres`, {
      x: 352,
      y: 310,
      size: 11,
      font: RobotoFont,
      color: rgb(0, 0, 0),
    });
    fifthPage.drawText("0.5 - 0.8 cents per", {
      x: 448,
      y: 318,
      size: 11,
      font: RobotoFont,
      color: rgb(0, 0, 0),
    });
    fifthPage.drawText(`${JCalc.kwToActual(power)} `, {
      x: 54,
      y: 190,
      size: 11,
      font: RobotoFont,
      color: rgb(0, 0, 0),
    });
    fifthPage.drawText(`${JCalc.kwhToActual(power * 7)} `, {
      x: 178,
      y: 194,
      size: 11,
      font: RobotoFont,
      color: rgb(0, 0, 0),
    });
    fifthPage.drawText(`${JCalc.kwhToActual(power * 7 * 30)}`, {
      x: 304,
      y: 194,
      size: 11,
      font: RobotoFont,
      color: rgb(0, 0, 0),
    });
    fifthPage.drawText(`${JCalc.kwhToActual(power * 7 * 30 * 12)}`, {
      x: 426,
      y: 194,
      size: 11,
      font: RobotoFont,
      color: rgb(0, 0, 0),
    });
    fifthPage.drawText(`${JCalc.kwToActual(power)}`, {
      x: 54,
      y: 84,
      size: 11,
      font: RobotoFont,
      color: rgb(0, 0, 0),
    });
    fifthPage.drawText(
      `${jcalc.getLCOE(energyPrice, reap, tax, false).toFixed(3)}`,
      {
        x: 220,
        y: 84,
        size: 11,
        font: RobotoFont,
        color: rgb(0, 0, 0),
      },
    );
    fifthPage.drawText(
      `${jcalc.getPaybackPeriod(energyPrice, reap, tax, false).toFixed(1)} years`,
      {
        x: 384,
        y: 84,
        size: 11,
        font: RobotoFont,
        color: rgb(0, 0, 0),
      },
    );

    const seventhPage = pages[6];
    seventhPage.drawText(
      `${JCalc.kwToActual(power)} solar PV system designed to generate approximately ${JCalc.kwhToActual(power * 7 * 30 * 12)}`,
      {
        x: 158,
        y: 629.5,
        size: 11,
        font: RobotoFont,
        color: rgb(0, 0, 0),
      },
    );
    seventhPage.drawText(
      `${JCalc.convertAcresToFeet(acres).toLocaleString()}`,
      {
        x: 474,
        y: 559,
        size: 11,
        font: RobotoFont,
        color: rgb(0, 0, 0),
      },
    );
    const pdfBytes = await pdfDoc.save();
    setPdfData(
      URL.createObjectURL(new Blob([pdfBytes], { type: "application/pdf" })),
    );
  }

  const getMoneyValue = (money) => {
    if (money >= 1000000) return (money / 1000000).toLocaleString();
    else return (money / 1000).toLocaleString();
  };

  const getMoneyExtension = (money) => {
    if (money >= 1000000) return "million";
    else return "thousand";
  };

  const handleTowerSelection = () => {
    setStartupFinished(true);
    setcheckPower(0);
    setcheckAcres(0);
    generatePDF();
  };

  const buttonSubmit = () => {
    setcheckPower(0);
    setcheckAcres(0);
    generatePDF();
    document.getElementById("setAcres").value = "";
    document.getElementById("setPower").value = "";
  };

  useEffect(() => {
    setJcalc(new JCalc(power, acres, 0.14, null, 10));
  }, [energyPrice, acres]);

  return (
    <>
      {!startupFinished && (
        <FirstScreen
          companyName={companyName}
          address={address}
          acres={acres}
          power={power}
          energyPrice={energyPrice}
          setEnergyPrice={setEnergyPrice}
          setCompanyName={setCompanyName}
          setAddress={setAddress}
          setAcres={setAcres}
          setPower={setPower}
          onTowerSelection={handleTowerSelection}
          checkAcres={checkAcres}
          checkPower={checkPower}
          setcheckAcres={setcheckAcres}
          setcheckPower={setcheckPower}
        ></FirstScreen>
      )}
      {startupFinished && (
        <div>
          <img
            src="logos/logo_mixed_black.png"
            className="im-logo"
            alt="Janta Power Mid-sized Logo"
          />
          <Row className="h-100 w-100 flex-nowrap">
            <Col
              md="3"
              xs="12"
              className="align-items-center"
              style={{ paddingLeft: "1.5em", textAlign: "center" }}
            >
              <Card className="w-100 shadow">
                <CardHeader className="title">
                  <CardTitle className="text-center h5">Inputs</CardTitle>
                </CardHeader>
              </Card>
              <div
                style={{
                  borderRadius: "10px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Card style={{ marginTop: "2em" }}>
                  <CardBody style={{ textAlign: "left" }}>
                    <Label>
                      What is the name of the company you are sending it to?
                    </Label>
                    <div className="border-inputs">
                      <Input
                        className="round-left input-color customer-select-dropdown"
                        placeholder={companyName}
                        onChange={(event) => {
                          const stringValue = event.target.value;
                          setCompanyName(stringValue);
                        }}
                      />
                    </div>
                  </CardBody>
                  <CardBody style={{ textAlign: "left" }}>
                    <Label>What is the address of the company?</Label>
                    <div className="border-inputs">
                      <Input
                        className="round-left input-color customer-select-dropdown"
                        placeholder={address}
                        onChange={(event) => {
                          const stringValue = event.target.value;
                          setAddress(stringValue);
                        }}
                      />
                    </div>
                  </CardBody>
                  <CardBody style={{ textAlign: "left" }}>
                    <Label>What is the energy price?</Label>
                    <div className="border-inputs">
                      <Input
                        className="round-left input-color customer-select-dropdown"
                        placeholder={energyPrice}
                        onChange={(event) => {
                          const numericValue = event.target.value;
                          setEnergyPrice(numericValue);
                        }}
                      />
                    </div>
                  </CardBody>
                  <CardBody style={{ textAlign: "left" }}>
                    <Label>How many acres do they need?</Label>
                    <div className="border-inputs">
                      <Input
                        className="round-left input-color customer-select-dropdown"
                        placeholder={acres}
                        onChange={(event) => {
                          const numericValue = event.target.value;
                          setcheckAcres(1);
                          setAcres(numericValue);
                          const tempjcalc = JCalc.fromArea(
                            Number(numericValue),
                            0.14,
                            null,
                            10,
                          );
                          setPower(tempjcalc.getTotalCapacity());
                          setJcalc(tempjcalc);
                        }}
                        disabled={checkPower !== 0}
                        id="setAcres"
                      />
                      <span>acres</span>
                    </div>
                  </CardBody>
                  <CardBody style={{ textAlign: "left" }}>
                    <Label>What is the scope of the project?</Label>
                    <div className="border-inputs">
                      <Input
                        className="round-left input-color customer-select-dropdown"
                        placeholder={power}
                        onChange={(event) => {
                          const stringValue = event.target.value;
                          setPower(stringValue);
                          setJcalc(
                            JCalc.fromScope(
                              Number(stringValue) * 1000,
                              0.14,
                              null,
                              10,
                            ),
                          );
                          setAcres(
                            Math.ceil(
                              JCalc.convertFeetToAcres(
                                JCalc.getLandForCapacity(
                                  Number(stringValue),
                                  10,
                                ).area,
                              ),
                            ),
                          );
                          setcheckPower(1);
                        }}
                        disabled={checkAcres !== 0}
                        id="setPower"
                      />
                      <span className="span-button">kW</span>
                    </div>{" "}
                  </CardBody>
                  <CardBody style={{ textAlign: "left" }}>
                    <Label>Reap? Tax Credits? </Label>
                    <div className="">
                      <Input
                        type="checkbox"
                        className="round-left input-color customer-select-dropdown"
                        onChange={(event) => {
                          setReap(event.target.checked);
                        }}
                        defaultChecked={reap}
                      />
                      <Input
                        type="checkbox"
                        className="round-left input-color customer-select-dropdown ms-5"
                        onChange={(event) => {
                          setTax(event.target.checked);
                        }}
                        defaultChecked={tax}
                      />
                    </div>
                    <div className="submit-button">
                      <button
                        className="btn btn-warning button-color"
                        onClick={buttonSubmit}
                      >
                        Submit
                      </button>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </Col>
            <Col
              md="9"
              className="align-items-center text-center"
              style={{
                textAlign: "center",
                fontSize: "30pt",
                whiteSpace: "nowrap",
                display: "block",
              }}
            >
              <div className="iframe-container">
                {pdfData ? (
                  <div>
                    <div className="shadow" style={{ marginBottom: "1em" }}>
                      <Card>
                        <CardHeader className="title">
                          <CardTitle className="text-center h5">
                            PDF Preview
                          </CardTitle>
                        </CardHeader>
                      </Card>
                    </div>

                    <iframe
                      src={pdfData}
                      width="100%"
                      height="800px" // Adjust height as needed
                      title="PDF Preview"
                      className="shadow"
                    />
                  </div>
                ) : (
                  <p>Generating PDF...</p>
                )}
              </div>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};
